<template>
  <div>
    <!-- 主体内容	 -->
    <el-card>
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }">
          <span class="nav_path"></span>首页
        </el-breadcrumb-item>
        <el-breadcrumb-item>供应商管理</el-breadcrumb-item>
        <el-breadcrumb-item>供应商列表</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="search_area" style="display: inline-block">
        <div style="display: inline;">
          <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable
                    @clear="getUserList" style="width: 320px">
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </div>
        <div style="display:inline-block;">
          <el-button type="primary" @click="addDialog" style="background-color: #42b7fb;border-color: #42b7fb;margin-left: 10px">添加供应商</el-button>
        </div>
      </div>

      <!-- 列表用户内容区域	 -->
      <el-table :data="userList" :header-cell-style="{background: '#F6F6F6',fontWeight: '600',color: '#333',}" style="width: 100%;border-top: 1px solid #f2f2f2">
        <el-table-column type="index" align="center" label="序号" min-width="60">
        </el-table-column>
        <el-table-column prop="username" align="center" label="名称" min-width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="password" show-overflow-tooltip align="center" label="密码" min-width="180" type="password">
        </el-table-column>
        <el-table-column prop="phone" align="center" label="电话" min-width="180">
        </el-table-column>
        <el-table-column prop="lastLogin" align="center" label="最近登录" min-width="180" :formatter="TimeFormatter">
        </el-table-column>
        <el-table-column prop="address" align="center" min-width="120" label="操作">
          <template slot-scope="scope">
            <el-button class="primary" type="text" @click="editUserlist(scope.row)">编辑</el-button>
            <span class="separator">|</span>
            <el-button class="primary" type="text" @click="removeUser(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页功能 -->
      <el-pagination align="left" @size-change="SizeChange" @current-change="CurrentChange"
                     :disabled="false"
                     :current-page="queryInfo.pagenum"
                     :page-sizes="[queryInfo.pagesize]" :page-size="queryInfo.pagesize"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加用户dialog对话框 -->

    <el-dialog align="left" title="添加供应商" :visible.sync="addUserVisible" width="550px" @close="closeAdduserDialog">
      <!--主体部分 -->
      <el-form :model="addUserForm" status-icon :rules="addUserFormRul" ref="addUserFormRel" label-width="100px"
               class="demo-ruleForm" style="align-items: center">
        <el-form-item label="供应商名称" prop="username">
          <el-input v-model="addUserForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="addUserForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model.number="addUserForm.phone"></el-input>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
				<el-button @click="addUserVisible = false">取 消</el-button>
				<el-button class="submit_btn" type="primary" @click="addUserList">确 定</el-button>
			</span>
    </el-dialog>

    <!-- 修改用户diaolog对话框 -->

    <el-dialog align="left" title="修改供应商" :visible.sync="editUserVisible" width="550px" @close="closeEdituserDialog">
      <!--主体部分 -->
      <el-form :model="editUserForm" status-icon :rules="addUserFormRul" ref="editUserFormRel" label-width="100px" class="demo-ruleForm">
        <el-form-item label="供应商名称">
          <el-input :disabled="true" v-model="editUserForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="editUserForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model.number="editUserForm.phone"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
      		<el-button @click="editUserVisible = false">取 消</el-button>
      		<el-button class="submit_btn" type="primary" @click="editUser">确 定</el-button>
      	</span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    //验证手机号码的规则
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[345789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      //返回一个错误提示
      cb(new Error('请输入合法的手机号码'))
    }


    return {
      //搜索的neirong
      searchVal: '',
      //用户列表参数
      queryInfo: {
        isSupplier: 1,
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      //用户列表
      userList: null,
      //总的数据条数
      total: 0,
      //添加用户dialog,控制显示隐藏
      addUserVisible: false,
      //添加用户参数
      addUserForm: {
        username: '',
        password: '',
        role: 4,
        phone: '',
        wx: 1
      },
      //添加用户规则
      addUserFormRul: {
        username: [{
          required: true,
          message: '请输入供应商名称',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
          {
            min: 6,
            message: '长度不能小于6个字符',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            // validator: checkMobile,
            pattern: /^1[345789]\d{9}$/,
            message: '请输入合法的手机号',
            trigger: 'change'
          }
        ]
      },

      //修改用户参数
      editUserForm: {
        username: '',
        phone: '',
        id: '',
        password: ''
      },

      //修改用户dialog,控制显示隐藏
      editUserVisible: false,
      //角色对话框
      editRoleVisible: false,
      //分配角色
      selectVal: '',
      //当前用户
      currentname: '',
      id: '',
      rid: ''
    }
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    //请求用户列表
    getUserList() {
      this.$http.post('user_query', this.queryInfo).then(res => {
        // console.log(res);
        if (res.data.code !== 200) return this.$message.error("查询失败！" + res.data.msg);
        this.userList = res.data.data.records;
        this.total = res.data.data.total;
      })
    },
    //每页数据条数改变时触发
    SizeChange(newval) {
      this.queryInfo.pagesize = newval;
      this.getUserList();
    },
    //当前页面改变时触发
    CurrentChange(newval) {
      this.queryInfo.pagenum = newval;
      this.getUserList();
    },
    TimeFormatter(row, column){
      const moment = require('moment');
      const date = row[column.property];
      if(date == undefined){return ''};
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },

    //删除用户
    removeUser(id) {
      this.$confirm('是否继续删除该供应商信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post(`user_delete/${id}`)
            .then(res => {
              //console.log(res);
              if (res.data.code == 400) return this.$message.warning('不允许删除！');
              if (res.data.code !== 200) return this.$message.error("删除失败！" + res.data.msg);

              this.$message.success('删除成功！');

              //刷新列表
              this.getUserList();
            })
      });
    },
    //点击添加新用户
    addDialog() {
      this.addUserVisible = !this.addUserVisible;
    },
    //点击确定发送添加新用户接口
    addUserList() {
      this.$refs.addUserFormRel.validate(valid => {
        //校验规则全部正确向后台发送请求
        if (!valid) return;
        this.$http.post('register', this.addUserForm).then(res => {
          //console.log(res);
          if (res.data.code !== 200) return this.$message.error("添加失败！" + res.data.msg);
          this.$message.success('添加成功！');
          //刷新列表
          this.getUserList();
          this.addUserVisible = !this.addUserVisible;
        })
      })
    },
    //关闭添加用户对话框，重置表单
    closeAdduserDialog() {
      this.$refs.addUserFormRel.resetFields();
    },
    closeEdituserDialog() {
      this.$refs.editUserFormRel.resetFields();
    },
    //修改用户信息事件
    editUserlist(item) {
      this.editUserVisible = !this.editUserVisible;
      this.editUserForm = {
        username: item.username,
        // role: item.role,
        phone: item.phone,
        id: item.id,
        password: item.password
      }
    },
    //点击确定发送请求修改用户信息
    editUser() {
      this.$refs.editUserFormRel.validate(valid => {
        //校验规则全部正确向后台发送请求
        if (!valid) return;
        this.$http.post('user_update', this.editUserForm).then(res => {
          if (res.data.code !== 200) return this.$message.error("修改失败！" + res.data.msg);
          this.$message.success("修改成功！");
          this.getUserList();
          this.editUserVisible = !this.editUserVisible;
        })
      })

    },
  }
}
</script>

<style scoped>
.demo-ruleForm .el-input {
  width: 70%;
}
.search_area {
  float: right;
  margin-bottom: 20px;
}
.el-breadcrumb {
  font-size: 14px;
  display: inline;
  float: left;
  margin: 15px 0 0 5px
}
.primary {
  font-size: 16px;

  font-weight: 500;
  color: #42B7FB;
}
.separator {
  color: #ccc;
  padding: 0 5px;
}
/deep/.el-table .cell:empty::before {
  content: "—";
  color: #999;
}
</style>
